<template>
  <div>
    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumbs">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h2>Galeri</h2>
          <ol>
            <li><router-link to="/" tag="a">Anasayfa</router-link></li>
            <li>Galeri</li>
          </ol>
        </div>
      </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio">
      <div class="container-fluid" data-aos="fade-up">
        <div class="section-header">
          <h2>Resim Galerimiz</h2>
          <!-- <p>Sed tamen tempor magna labore dolore dolor sint tempor duis magna elit veniam aliqua esse amet veniam enim export quid quid veniam aliqua eram noster malis nulla duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore</p> -->
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">Hepsi</li>
              <!-- <li data-filter=".filter-panjur">Panjur</li>
                <li data-filter=".filter-cam">Cam Balkon</li>
                <li data-filter=".filter-asma">Asma Tavan</li>
                <li data-filter=".filter-kapı">Kapı-Pencere-Sineklik</li> -->
            </ul>
          </div>
        </div>

        <div
          class="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
            <img
              src="../assets/images/onur-pen-galeri-3.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="portfolio-info">
              <h4>Onur Pen</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
            <img
              src="../assets/images/onur-pen-galeri-2.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="portfolio-info">
              <h4>PVC Kare Tavan</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
            <img
              src="../assets/images/onur-pen-galeri-1.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="portfolio-info">
              <h4>Sürme PVC</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
            <img
              src="../assets/images/onur-pen-galeri-4.jpg"
              class="img-fluid"
              alt="Motorlu Saç Kepenk"
            />
            <div class="portfolio-info">
              <h4>Motorlu Saç Kepenk</h4>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
            <img src="../assets/images/3.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Panjur</h4>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
            <img src="../assets/images/4.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Motorlu Alüminyum Panjur</h4>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-cam">
            <img src="../assets/images/5.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Cam Balkon</h4>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-cam">
            <img src="../assets/images/6.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Cam Balkon</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
            <img src="../assets/images/7.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Kapı-Pencere-Sineklik</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
            <img src="../assets/images/8.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Kapı-Pencere-Sineklik</h4>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-asma">
            <img src="../assets/images/9.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Asma Tavan</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
            <img src="../assets/images/10.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Kapı-Pencere-Sineklik</h4>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
            <img src="../assets/images/11.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Kapı-Pencere-Sineklik</h4>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-active">
            <img src="../assets/images/12.jpeg" class="img-fluid" alt="" />
            <div class="portfolio-info">
              <h4>Duşakabin</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-cam">
            <img
              src="../assets/images/cam-balkon.jpeg"
              class="img-fluid"
              alt=""
            />
            <div class="portfolio-info">
              <h4>Cam Balkon</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Portfolio Section -->

    <!-- ======= Call To Action Section ======= -->
    <section id="call-to-action">
      <div class="container" data-aos="zoom-out">
        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
            <h3 class="cta-title text-left">Bizimle İletişime Geç</h3>
            <p class="cta-text text-left">
              Onur-Pen, tecrübeli üretim kadrosu ile üretimini yapmış olduğu PVC
              Kapı, Pencere, Sineklik, Duşakabin, Panjur, Asma Tavan, Cam Balkon
              ürünlerinin satış, satış sonrası destek bölümleri ve müşteri
              memnuniyeti odaklı çalışmalarına hızla devam etmektedir.
            </p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle" href="tel:+905558870097"
              >Hemen Ara</a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action Section -->

    <a
      href="#"
      class="container-fluid back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<script>
export default {
  created() {
    //     console.log(window.location.pathname);
    //     if(window.location.pathname == "/galeri"){
    //  setTimeout(()=>{
    //          window.location.reload();
    //  },1000)
    //}
  },
  methods: {},
};
</script>

<style>
</style>