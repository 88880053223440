<template>
  <div>
    <!-- ======= hero Section ======= -->
    <section id="hero">
      <div class="hero-content container" data-aos="fade-up">
        <h4>
          PVC kapı-pencere-sineklik tamirat & tadilat, panjur, duşakabin, cam
          balkon, alüminyum doğrama, asma tavan işleri için doğru adres !
        </h4>
        <div>
          <router-link tag="a" to="/hizmetler" class="btn-get-started scrollto"
            >Hizmetlerimiz</router-link
          >
          <router-link tag="a" to="/iletisim" class="btn-projects scrollto"
            >Teklif Al</router-link
          >
        </div>
      </div>
      <div
        id="carouselExampleControls"
        class="hero-slider carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item active"
            style="
              background-image: url('assets/img/hero-carousel/cam-balkon.jpeg');
            "
          ></div>
          <div
            class="carousel-item"
            style="background-image: url('assets/img/hero-carousel/5.jpeg')"
          ></div>
          <div
            class="carousel-item"
            style="background-image: url('assets/img/hero-carousel/9.jpeg')"
          ></div>
        </div>
      </div>
    </section>
    <!-- End Hero Section -->

    <main id="main">
      <!-- ======= About Section ======= -->
      <section id="about">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-6 about-img">
              <img src="../assets/images/2-1.jpeg" alt="" />
            </div>
            <div class="col-lg-6 content">
              <h2>Onur-Pen</h2>
              <h3>PVC Kapı-Pencere-Sineklik, Panjur, Alüminyum Doğrama</h3>
              <h6>
                Onur-Pen, tecrübeli üretim kadrosu ile üretimini yapmış olduğu
                PVC Kapı, Pencere, Sineklik, Duşakabin, Panjur, Asma Tavan, Cam
                Balkon ürünlerinin satış, satış sonrası destek bölümleri ve
                müşteri memnuniyeti odaklı çalışmalarına hızla devam etmektedir.
              </h6>
              <ul>
                <li><i class="bi bi-journal-text"></i> Onur - Pen</li>
                <li>
                  <i class="bi bi-telephone"></i>
                  Telefon: +90 555 887 00 97
                </li>
                <li>
                  <i class="bi bi-envelope"></i>
                  E-Mail: onurbuber645@icloud.com
                </li>
                <li><i class="bi bi-chevron-right"></i>Onur BÜBER / Kurucu</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <!-- End About Section -->

      <!-- ======= Services Section ======= -->
      <section id="services">
        <div class="container-fluid" data-aos="fade-up">
          <div class="section-header">
            <h2>Hizmetlerimiz</h2>
            <p>
              Son teknolojiyle üretilmiş ürünlerimiz, hayatı kolaylaştıran bu
              çözümlerin hepsinde ve daha birçok alanda güvenle
              kullanılmaktadır.
            </p>
          </div>

          <div class="row gy-4">
            <div class="col-lg-6 pt-3" data-aos="fade-up" data-aos-delay="100">
              <div class="box">
                <div class="icon"><i class="bi bi-dash-circle"></i></div>
                <h4 class="title"><a href="">Alüminyum Doğrama</a></h4>
                <p class="description">
                  Alüminyum doğrama, 6 metreye kadar uzunlukta olan farklı
                  alüminyum profillerin kesilerek işlenmesi ve bu ürünlerin
                  kapı, pencere, cam balkon sistemi olarak hayatımızda işlevsel
                  bir yer alması işlemidir.
                </p>
              </div>
            </div>

            <div class="col-lg-6 pt-3" data-aos="fade-up" data-aos-delay="100">
              <div class="box">
                <div class="icon"><i class="bi bi-rainbow"></i></div>
                <h4 class="title"><a href="">Duşakabin</a></h4>
                <p class="description">
                  Duşakabinler, banyolara dekoratif bir görüntü desteği sağlayan
                  ve bunun yanı sıra duş alma esnasında oluşacak su
                  sıçramalarının önünü alarak sıcak havanın muhafazasını
                  sağlayan işlevsel banyo ürünlerden biridir.
                </p>
              </div>
            </div>

            <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="300">
              <div class="box">
                <div class="icon"><i class="bi bi-flower2"></i></div>
                <h4 class="title"><a href="">Asma Tavan</a></h4>
                <p class="description">
                  Asma tavan, yukarıdaki zemin kat levhasından asılan ikincil
                  tavanlardır ve zemin levhanın alt tarafı ile asma tavanın üstü
                  arasında bir boşluk oluşturur.
                </p>
              </div>
            </div>

            <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="300">
              <div class="box">
                <div class="icon"><i class="bi bi-stop-btn"></i></div>
                <h4 class="title"><a href="">Cam Balkon</a></h4>
                <p class="description">
                  Cam balkon sistemleri sayesinde dört mevsimi birleştirdik.
                  Modern yapısı, estetik görünümü, şık tasarımı ve kaliteli
                  malzemeleri ile günün her anında balkonlarda vakit
                  geçirebilirsiniz.
                </p>
              </div>
            </div>
            <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="400">
              <div class="box">
                <div class="icon">
                  <i class="bi bi-brightness-high-fill"></i>
                </div>

                <h4 class="title"><a href="">Panjur</a></h4>
                <p class="description">
                  Güneşi ve rüzgârı önlemeye, ışığı azaltmaya yarayan, açılır -
                  kapanır, plâstikten veya metal gereçlerden yapılmış
                  malzemelerdir.
                </p>
              </div>
            </div>
            <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="400">
              <div class="box">
                <div class="icon"><i class="bi bi-back"></i></div>
                <h4 class="title"><a href="">Kapı-Pencere-Sineklik</a></h4>
                <p class="description">
                  Kapı-pencere-sineklik sistemlerinde darbe ve esneme
                  mukavemetinin yüksek olmasından dolayı PVC ürünler kullanılır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Services Section -->

      <!-- ======= Portfolio Section ======= -->
      <section id="portfolio" class="portfolio">
        <div class="container-fluid" data-aos="fade-up">
          <div class="section-header">
            <h2>Resim Galerimiz</h2>
            <!-- <p>Sed tamen tempor magna labore dolore dolor sint tempor duis magna elit veniam aliqua esse amet veniam enim export quid quid veniam aliqua eram noster malis nulla duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore</p> -->
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">Hepsi</li>
                <!-- <li data-filter=".filter-panjur">Panjur</li>
                <li data-filter=".filter-cam">Cam Balkon</li>
                <li data-filter=".filter-asma">Asma Tavan</li>
                <li data-filter=".filter-kapı">Kapı-Pencere-Sineklik</li> -->
              </ul>
            </div>
          </div>

          <div
            class="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
              <img
                src="../assets/images/onur-pen-galeri-3.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info">
                <h4>Onur Pen</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
              <img
                src="../assets/images/onur-pen-galeri-2.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info">
                <h4>PVC Kare Tavan</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
              <img
                src="../assets/images/onur-pen-galeri-1.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info">
                <h4>Sürme PVC</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
              <img
                src="../assets/images/onur-pen-galeri-4.jpg"
                class="img-fluid"
                alt="Motorlu Saç Kepenk"
              />
              <div class="portfolio-info">
                <h4>Motorlu Saç Kepenk</h4>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-panjur">
              <img src="../assets/images/3.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Panjur</h4>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
              <img src="../assets/images/4.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Motorlu Alüminyum Panjur</h4>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-cam">
              <img src="../assets/images/5.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Cam Balkon</h4>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-cam">
              <img src="../assets/images/6.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Cam Balkon</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
              <img src="../assets/images/7.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Kapı-Pencere-Sineklik</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
              <img src="../assets/images/8.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Kapı-Pencere-Sineklik</h4>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-asma">
              <img src="../assets/images/9.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Asma Tavan</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
              <img src="../assets/images/10.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Kapı-Pencere-Sineklik</h4>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-kapı">
              <img src="../assets/images/11.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Kapı-Pencere-Sineklik</h4>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-active">
              <img src="../assets/images/12.jpeg" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <h4>Duşakabin</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-cam">
              <img
                src="../assets/images/cam-balkon.jpeg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info">
                <h4>Cam Balkon</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Portfolio Section -->

      <!-- ======= Clients Section ======= -->
      <!-- <section id="clients">
        <div class="container-fluid" data-aos="fade-up">
          <div class="section-header">
            <h2>Bazı Müşterilerimiz</h2>
          </div>

          <div
            class="clients-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="swiper-wrapper align-items-center">
              <div class="swiper-slide">
                <img src="assets/img/clients/3.png" class="img-fluid" alt="" />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </section> -->
      <!-- End Clients Section -->

      <!-- ======= Call To Action Section ======= -->
      <section class="mt-3" id="call-to-action">
        <div class="container" data-aos="zoom-out">
          <div class="row">
            <div class="col-lg-9 text-left text-lg-start">
              <h3 class="cta-title">Bizimle İletişime Geç</h3>
              <p class="cta-text">
                Onur-Pen, tecrübeli üretim kadrosu ile üretimini yapmış olduğu
                PVC Kapı, Pencere, Sineklik, Duşakabin, Panjur, Asma Tavan, Cam
                Balkon ürünlerinin satış, satış sonrası destek bölümleri ve
                müşteri memnuniyeti odaklı çalışmalarına hızla devam etmektedir.
              </p>
            </div>
            <div class="col-lg-3 cta-btn-container text-center">
              <a class="cta-btn align-middle" href="tel:+905558870097"
                >Hemen Ara</a
              >
            </div>
          </div>
        </div>
      </section>
      <!-- End Call To Action Section -->
    </main>
    <!-- End #main -->
  </div>
</template>

<script>
export default {
  name: "HomeMainPage",
  data() {
    return {};
  },
  components: {},

  created() {},
};
</script>

<style>
</style>