<template>
  <div>
    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumbs">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h2>İletişim</h2>
          <ol>
            <li><router-link to="/" tag="a">Anasayfa</router-link></li>
            <li>İletişim</li>
          </ol>
        </div>
      </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
          <h2>Üretimhane Ve Merkez Ofis</h2>
          <p>Gebiz, Serik, ANTALYA</p>
        </div>

        <div class="row contact-info">
          <div class="col-md-4">
            <div class="contact-address">
              <i class="bi bi-geo-alt"></i>
              <h3>Adres</h3>
              <address>
                Gebiz Mahallesi Şevket Softaoğlu Caddesi No: 3 Dükkan 1
              </address>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-phone">
              <i class="bi bi-phone"></i>
              <h3>Telefon</h3>
              <p><a href="tel:+905558870097">+90 555 887 00 97</a></p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-email">
              <i class="bi bi-envelope"></i>
              <h3>E-MAİL</h3>
              <p>
                <a href="mailto:onurbuber645@icloud.com"
                  >onurbuber645@icloud.com</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container mb-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236438.58902664395!2d30.723370310686086!3d36.98383724305191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c4873c7f4020bf%3A0xbb38f0fe71b53f11!2sOnur%20Pen!5e0!3m2!1sen!2str!4v1651843446622!5m2!1sen!2str"
          width="100%"
          height="450"
          style="border-radius: 8px; border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <!-- <div class="container">
        <div class="form">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row">
              <div class="form-group col-md-6">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
              </div>
              <div class="form-group col-md-6 mt-3 mt-md-0">
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
              </div>
            </div>
            <div class="form-group mt-3">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
            </div>
            <div class="form-group mt-3">
              <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
            </div>

            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>

            <div class="text-center"><button type="submit">Send Message</button></div>
          </form>
        </div>

      </div> -->
    </section>

    <!-- ======= Call To Action Section ======= -->
    <section id="call-to-action">
      <div class="container" data-aos="zoom-out">
        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
            <h3 class="cta-title text-left">Bizimle İletişime Geç</h3>
            <p class="cta-text text-left">
              Onur-Pen, tecrübeli üretim kadrosu ile üretimini yapmış olduğu PVC
              Kapı, Pencere, Sineklik, Duşakabin, Panjur, Asma Tavan, Cam Balkon
              ürünlerinin satış, satış sonrası destek bölümleri ve müşteri
              memnuniyeti odaklı çalışmalarına hızla devam etmektedir.
            </p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle" href="tel:+905558870097"
              >Hemen Ara</a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action Section -->

    <a
      href="#"
      class="container-fluid back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>