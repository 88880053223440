<template>
  <div>
    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumbs">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h2>Hakkımızda</h2>
          <ol>
            <li><router-link tag="a" to="/">Anasayfa</router-link></li>
            <li>Hakkımızda</li>
          </ol>
        </div>
      </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
          <h2>Onur-Pen</h2>
          <p>SERİK / ANTALYA</p>
        </div>
        <p>
          Onur-Pen, tecrübeli üretim kadrosu ile üretimini yapmış olduğu PVC
          Kapı, Pencere, Sineklik, Duşakabin, Panjur, Asma Tavan, Cam Balkon
          ürünlerinin satış, satış sonrası destek bölümleri ve müşteri
          memnuniyeti odaklı çalışmalarına hızla devam etmektedir.
          <br />
          <span style="font-weight: bold"
            >“ PVC Kapı-Pencere-Sineklik, Panjur, Alüminyum Doğrama, Duşakabin, Asma Tavan, Cam Balkon ”</span
          >
        </p>
      </div>
      <!-- ======= Skills  ======= -->
      <div class="skills container" data-aos="fade-up">
        <div class="section-title">
          <h2>Neden Biz?</h2>
        </div>
        <div class="row skills-content">
          <div class="col">
            <div class="progress">
              <span class="skill">Güven <i class="val">98%</i></span>
              <div class="progress-bar-wrap">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="98"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 98%"
                ></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">KALİTE <i class="val">98%</i></span>
              <div class="progress-bar-wrap">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="98"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 98%"
                ></div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="progress">
              <span class="skill">Uzmanlık <i class="val">99%</i></span>
              <div class="progress-bar-wrap">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="99"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 99%"
                ></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Yerli ÜRETİM <i class="val">100%</i></span>
              <div class="progress-bar-wrap">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 100%"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Skills -->
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>