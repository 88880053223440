<template>
  <div>
    <!-- ======= Top Bar ======= -->
    <section id="topbar" class="d-flex align-items-center">
      <div
        class="container-fluid d-flex justify-content-center justify-content-md-between"
      >
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope d-flex align-items-center"
            ><a href="mailto:onurbuber645@icloud.com"
              >onurbuber645@icloud.com</a
            ></i
          >
          <i class="bi bi-phone d-flex align-items-center ms-4"
            ><a href="tel:+905558870097">+90 555 887 00 97</a></i
          >
          <!-- <i class="bi bi-map d-flex align-items-center ms-4"><a>Ankara-Türkiye</a></i> -->
        </div>
        <div class="social-links d-none d-md-flex align-items-center">
          <a
            href="https://www.facebook.com/Onurbuber1997"
            target="_blank"
            class="facebook"
            ><i class="bi bi-facebook"></i
          ></a>
          <a
            href="https://www.instagram.com/o.buber0097/"
            target="_blank"
            class="instagram"
            ><i class="bi bi-instagram"></i
          ></a>
        </div>
      </div>
    </section>
    <!-- End Top Bar-->

    <!-- ======= Header ======= -->
    <header id="header" class="d-flex align-items-center">
      <div class="container-fluid d-flex justify-content-between">
        <div id="logo">
          <router-link tag="a" to="/"
            ><img src="../assets/logo.jpeg" alt="" />
          </router-link>
          <!-- <h1><a href="/">Onur - Pen</a></h1> -->
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li @click="scrollto">
              <router-link tag="a" to="/" class="nav-link scrollto active"
                >Anasayfa</router-link
              >
            </li>
            <li class="dropdown" @click="dropdown">
              <router-link tag="a" to="/hizmetler">
                Hizmetlerimiz
                <i class="bi bi-chevron-down"></i
              ></router-link>

              <ul>
                <!-- <li><a href="#">Drop Down 1</a></li>
                <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>-->
                <li @click="scrollto">
                  <router-link tag="a" to="/hizmetler">Panjur</router-link>
                </li>
                <li @click="scrollto">
                  <router-link tag="a" to="/hizmetler">Duşakabin</router-link>
                </li>
                <li @click="scrollto">
                  <router-link tag="a" to="/hizmetler">Asma Tavan</router-link>
                </li>
                <li @click="scrollto">
                  <router-link tag="a" to="/hizmetler">Cam Balkon</router-link>
                </li>
                <li @click="scrollto">
                  <router-link tag="a" to="/hizmetler"
                    >Alüminyum Doğrama</router-link
                  >
                </li>
                <li @click="scrollto">
                  <router-link tag="a" to="/hizmetler"
                    >Kapı-Pencere-Sineklik</router-link
                  >
                </li>
              </ul>
            </li>
            <li @click="scrollto">
              <router-link tag="a" to="/galeri" class="nav-link scrollto"
                >Galeri</router-link
              >
            </li>
            <li @click="scrollto">
              <router-link tag="a" to="/hakkimizda" class="nav-link scrollto"
                >Hakkımızda</router-link
              >
            </li>
            <li @click="scrollto">
              <router-link tag="a" to="/iletisim" class="nav-link scrollto"
                >İletişim</router-link
              >
            </li>
          </ul>
          <i @click="mobile" class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <!-- .navbar -->
      </div>
    </header>
    <!-- End Header -->
  </div>
</template>

<script>
export default {
  name: "HeaderTop",
  data() {
    return {
      path: "",
    };
  },
  async created() {
    window.addEventListener("scroll", () => {
      if (scrollY >= 40) {
        document.getElementById("header").classList.add("header-active");
      } else {
        document.getElementById("header").classList.remove("header-active");
      }
    });
  },

  methods: {
    mobile() {
      let navbarToggle = document.querySelector(".mobile-nav-toggle");
      let navbar = document.querySelector("#navbar");

      if (navbar.classList.contains("navbar-mobile")) {
        navbarToggle.classList.toggle("bi-x");
        navbar.classList.remove("navbar-mobile");
        navbarToggle.classList.toggle("bi-list");
      } else {
        navbar.classList.add("navbar-mobile");
        navbarToggle.classList.toggle("bi-list");
        navbarToggle.classList.toggle("bi-x");
      }
    },
    dropdown() {
      let navbar = document.querySelector("#navbar");
      let drop = document.querySelector(".dropdown > a");
      if (navbar.classList.contains("navbar-mobile")) {
        drop.nextElementSibling.classList.toggle("dropdown-active");
      }
    },
    scrollto() {
      let navbarToggle = document.querySelector(".mobile-nav-toggle");
      navbarToggle.classList.toggle("bi-list");
      let navbar = document.querySelector("#navbar");
      if (navbar.classList.contains("navbar-mobile")) {
        navbar.classList.remove("navbar-mobile");
        navbarToggle.classList.toggle("bi-x");
      }
    },
  },
};
</script>

<style>
</style>