import Vue from 'vue'
import VueRouter from 'vue-router'
// import firebase from "../firebase/firebase"
// import Login from '../views/Login.vue'
// import Admin from '../views/Admin/Admin.vue'
import Home from '../Views/HomeMainPage.vue'
import Contact from '../Views/ContactPage.vue'
import About from '../Views/AboutUsPage.vue'
import Hizmetler from '../Views/HizmetlerPage.vue'
import Galeri from '../Views/GaleriPage.vue'

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'Home',
    component: Home

}, {
    path: '/iletisim',
    name: 'Contact',
    component: Contact

}, {
    path: '/hakkimizda',
    name: 'About',
    component: About

}, {
    path: '/hizmetler',
    name: 'Hizmetler',
    component: Hizmetler

}, {
    path: '/galeri',
    name: 'Galeri',
    component: Galeri

}, ]
const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     const data = firebase.firebase;
//     const user = data.auth().currentUser;
//     if (requiresAuth && !user) {
//         next("/login")
//     } else {
//         next();
//     }
// })

export default router