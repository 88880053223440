<template>
  <div>
    <!-- ======= Footer ======= -->
    <footer class="page-footer">
      <div class="container-fluid">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-3 py-3">
            <!-- <img src="../assets/logo.jpeg" alt="" style="width:100px;"> -->
            <h3>Onur-Pen<span class="text-primary"></span></h3>
            <p>Gebiz Mahallesi Şevket Softaoğlu Caddesi No: 3 Dükkan 1</p>

            <p>
              <a href="mailto:onurbuber645@icloud.com"
                >onurbuber645@icloud.com</a
              >
            </p>
            <p><a href="tel:+905558870097">+90 555 887 00 97</a></p>
          </div>
          <div class="col-lg-3 py-3">
            <h5>Popüler Hizmetlerimiz</h5>
            <ul class="footer-menu">
              <li>
                <router-link tag="a" to="/hizmetler">Panjur</router-link>
              </li>
              <li>
                <router-link tag="a" to="/hizmetler">Duşakabin</router-link>
              </li>
              <li>
                <router-link tag="a" to="/hizmetler">Asma Tavan</router-link>
              </li>
              <li>
                <router-link tag="a" to="/hizmetler">Cam Balkon</router-link>
              </li>
              <li>
                <router-link tag="a" to="/hizmetler"
                  >Kapı-Pencere-Sineklik</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-3 py-3">
            <h5>Hızlı Erişim</h5>
            <ul class="footer-menu">
              <li>
                <router-link tag="a" to="/iletisim">Teklif Al</router-link>
              </li>
              <li>
                <router-link tag="a" to="/hizmetler">Hizmetler</router-link>
              </li>
              <li><router-link tag="a" to="/galeri">Galeri</router-link></li>
              <li>
                <router-link tag="a" to="/hakkimizda">Hakkımızda</router-link>
              </li>
              <li>
                <router-link tag="a" to="/iletisim">İletişim</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 py-3">
            <h5>Teklif Al</h5>
            <form action="#">
              <input
                type="text"
                class="form-control"
                placeholder="Enter your mail.."
              />
            </form>

            <div class="mt-4">
              <p>
                İhtiyacınız olan ürün veya hizmet ile ilgili 2/24 WhatsApp
                hattımızdan
                <a href="tel:+905558870097">buraya</a>
                tıklayarak destek alabilirsiniz!
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 py-2 text-center">
            <p id="copyright">
              &copy; 2022
              <a href="https://onurpen.com">Onur-Pen</a>. All rights reserved.
              Developed by<a href="https://ultof.com" target="_blank"> ULTOF</a>
            </p>
          </div>
          <!-- <div class="col-sm-6 py-2 text-right">
          <div class="d-inline-block px-3">
            <a href="#">Privacy</a>
          </div>
          <div class="d-inline-block px-3">
            <a href="#">Contact Us</a>
          </div>
        </div> -->
        </div>
      </div>
      <!-- .container -->
    </footer>
    <!-- .page-footer -->

    <a
      href="#"
      class="container-fluid back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>

    <!-- End Footer -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>