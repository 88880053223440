<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/HeaderTop.vue";
import Footer from "./components/FooterMainBottom.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  created() {
    /** Back to top button */
    window.addEventListener("scroll", () => {
      if (scrollY >= 100) {
        document.querySelector(".back-to-top").classList.add("active");
      } else {
        document.querySelector(".back-to-top").classList.remove("active");
      }
    });
  },
};
</script>

<style>
</style>
