<template>
  <div>
    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumbs">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h2>Hizmetlerimiz</h2>
          <ol>
            <li><router-link to="/" tag="a">Anasayfa</router-link></li>
            <li>Hizmetlerimiz</li>
          </ol>
        </div>
      </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Contact Section ======= -->
    <section id="services">
      <div class="container-fluid" data-aos="fade-up">
        <div class="section-header">
          <h2>Hizmetlerimiz</h2>
          <p>
            Son teknolojiyle üretilmiş ürünlerimiz, hayatı kolaylaştıran bu
            çözümlerin hepsinde ve daha birçok alanda güvenle kullanılmaktadır.
          </p>
        </div>

        <div class="row gy-4">
          <div class="col-lg-6 pt-3" data-aos="fade-up" data-aos-delay="100">
            <div class="box">
              <div class="icon"><i class="bi bi-dash-circle"></i></div>
              <h4 class="title"><a href="">Alüminyum Doğrama</a></h4>
              <p class="description">
                Alüminyum doğrama, 6 metreye kadar uzunlukta olan farklı
                alüminyum profillerin kesilerek işlenmesi ve bu ürünlerin kapı,
                pencere, cam balkon sistemi olarak hayatımızda işlevsel bir yer
                alması işlemidir.
              </p>
            </div>
          </div>

          <div class="col-lg-6 pt-3" data-aos="fade-up" data-aos-delay="100">
            <div class="box">
              <div class="icon"><i class="bi bi-rainbow"></i></div>
              <h4 class="title"><a href="">Duşakabin</a></h4>
              <p class="description">
                Duşakabinler, banyolara dekoratif bir görüntü desteği sağlayan
                ve bunun yanı sıra duş alma esnasında oluşacak su sıçramalarının
                önünü alarak sıcak havanın muhafazasını sağlayan işlevsel banyo
                ürünlerden biridir.
              </p>
            </div>
          </div>

          <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="300">
            <div class="box">
              <div class="icon"><i class="bi bi-flower2"></i></div>
              <h4 class="title"><a href="">Asma Tavan</a></h4>
              <p class="description">
                Asma tavan, yukarıdaki zemin kat levhasından asılan ikincil
                tavanlardır ve zemin levhanın alt tarafı ile asma tavanın üstü
                arasında bir boşluk oluşturur.
              </p>
            </div>
          </div>

          <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="300">
            <div class="box">
              <div class="icon"><i class="bi bi-stop-btn"></i></div>
              <h4 class="title"><a href="">Cam Balkon</a></h4>
              <p class="description">
                Cam balkon sistemleri sayesinde dört mevsimi birleştirdik.
                Modern yapısı, estetik görünümü, şık tasarımı ve kaliteli
                malzemeleri ile günün her anında balkonlarda vakit
                geçirebilirsiniz.
              </p>
            </div>
          </div>
          <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="400">
            <div class="box">
              <div class="icon">
                <i class="bi bi-brightness-high-fill"></i>
              </div>

              <h4 class="title"><a href="">Panjur</a></h4>
              <p class="description">
                Güneşi ve rüzgârı önlemeye, ışığı azaltmaya yarayan, açılır -
                kapanır, plâstikten veya metal gereçlerden yapılmış
                malzemelerdir.
              </p>
            </div>
          </div>
          <div class="col-lg-6 pt-5" data-aos="fade-up" data-aos-delay="400">
            <div class="box">
              <div class="icon"><i class="bi bi-back"></i></div>
              <h4 class="title"><a href="">Kapı-Pencere-Sineklik</a></h4>
              <p class="description">
                Kapı-pencere-sineklik sistemlerinde darbe ve esneme
                mukavemetinin yüksek olmasından dolayı PVC ürünler kullanılır.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ======= Call To Action Section ======= -->
    <section id="call-to-action">
      <div class="container" data-aos="zoom-out">
        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
            <h3 class="cta-title text-left">Bizimle İletişime Geç</h3>
            <p class="cta-text text-left">
              Onur-Pen, tecrübeli üretim kadrosu ile üretimini yapmış olduğu PVC
              Kapı, Pencere, Sineklik, Duşakabin, Panjur, Asma Tavan, Cam Balkon
              ürünlerinin satış, satış sonrası destek bölümleri ve müşteri
              memnuniyeti odaklı çalışmalarına hızla devam etmektedir.
            </p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle" href="tel:+905558870097"
              >Hemen Ara</a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action Section -->

    <a
      href="#"
      class="
        container-fluid
        back-to-top
        d-flex
        align-items-center
        justify-content-center
      "
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>